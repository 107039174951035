import React, { useState, useEffect } from 'react'
import './App.css';
import HomePage from './pages/HomePage';
import Archives from './pages/Archives';
import Nav from './components/Nav';
import Footer from './components/Footer';
import {HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import MentionsLegales from './pages/MentionsLegales';
import LogInPage from './pages/LogInPage';
import AddEvents from './pages/AddEvents';
import Account from './pages/Account';
import AddArticle from './pages/AddArticle';
import CustomCursor from './components/CustomCursor';

function App() {

  const [deviceWidth, setDeviceWidth] = useState();
  const [mobileNavActive, setMobileNavActive] = useState(false);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
};


useEffect(() => {
   handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  
}, [])

  return (

    <BrowserRouter basename="/">
       {/* <CustomCursor /> */}
       <Nav deviceWidth={deviceWidth} mobileNavActive={mobileNavActive} setMobileNavActive={setMobileNavActive}/>
       <Routes>
          <Route exact path="/" element={<HomePage setMobileNavActive={setMobileNavActive} />}></Route>
          <Route path="/home" element={<HomePage setMobileNavActive={setMobileNavActive}/>}></Route>
          <Route path="/archives" element={<Archives />}></Route>
          <Route path="/login" element={<LogInPage />}></Route>
          <Route path="/mentions_legales" element={<MentionsLegales />}></Route>
          <Route path="/ajouter_evenements" element={<AddEvents />}></Route>
          <Route path="/ajouter_articles" element={<AddArticle />}></Route>
          <Route path="/account" element={<Account />}></Route>
       </Routes>
       <Footer deviceWidth={deviceWidth} />
      </BrowserRouter>
 
  );
}

export default App;
