import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

export default function DropDown({ subMenu, dropdown, index }) {

    console.log(subMenu);
    
  return (
    <ul className={dropdown === index ? "subMenu show" : "subMenu hide"}>
        {subMenu.map(item => {
         return <li key={index + item.titre}><Link to={`/${item.url}`}>{item.titre}</Link></li>
        })}
    </ul>
  )
}
