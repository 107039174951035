import React, {useState, useEffect} from 'react'
import {db} from '../firebase-config'
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, query } from 'firebase/firestore'
import { BsCalendar3 } from "react-icons/bs";

export default function Carousel({setSelectedEvent, selectedEvent, scrollYPosition}) {

    const [events, setEvents] = useState([])

    const gridTemplateColumns = `repeat(${events.length}, 1fr)`;

    const navigate = useNavigate()

    let today = new Date()

    console.log(today.getMonth());
    
    const handleEvent = async (event) => {
      window.scrollTo(0, (scrollYPosition - 250))
      setSelectedEvent(event)
      
    }


    const loadEvents = async () => {
        setEvents([])
        const ref = collection(db, "calendrier")
        const querySnapshot = await getDocs(ref)
        querySnapshot.forEach((doc) => {
          const startDateStr = doc.data().start; 
          
          const [day, month, year] = startDateStr.split('/');

          const date = new Date(`${month}-${day}-${year}`);
            
            if((date.getMonth() === today.getMonth() && date.getYear() === today.getYear())) {
                setEvents(events => [doc.data(), ...events])
            }
        })
    }

    console.log(events.length);
    

    useEffect(() => {
        loadEvents()
    }, [])


    console.log(events);

    
  return (
    <div className='carousel' style={{gridTemplateColumns: gridTemplateColumns}}>
      {events.length > 0 ? 
      events.map(event => (
        <div key={event.id} className="event" onClick={() => handleEvent(event)}>
            <h4>{event.title}</h4>
            <p>{event.start} - {event.end.slice(10.16)}</p>
        </div>
      ))   :

       <div className="no_event">
         <p><BsCalendar3 /> Pas d'événements ce mois-ci</p>
       </div>
      }
      </div>
  )
}
