import React from 'react'
// import AddArticleForm from '../components/AddArticleForm'
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

export default function AddArticle() {

  const navigate = useNavigate()

  return (
    <div className='addArticles'>
       <button className="back" onClick={() => navigate('/account')}><TiArrowBack /> Retour</button>
      {/* <AddArticleForm /> */}
    </div>
  )
}
