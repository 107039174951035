import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

export default function Footer({deviceWidth}) {
  return (
    <div className='footer'>
      <div className="financeurs">
        {deviceWidth > 800 ?
        <img src="https://action-rse.fr/Assets/bandeau_RSE_NFC.jpg" alt="" />
        :
        <img src="https://action-rse.fr/Assets/bandeauFinancerusActionRSE.PNG" alt="" />
        }
      </div>
      <Link to="/mentions_legales">Mentions légales</Link>
    </div>
  )
}
