import React, {useState} from 'react'
import { MdPersonPin } from "react-icons/md";

export default function Person({name, professionalFunction, tel, id, hoveredId, setHoveredId}) {  

  return (
    <div 
    id={'person_' + id} 
    className='person'
    onMouseEnter={() => setHoveredId(id)} 
    onMouseLeave={() => setHoveredId(null)}
    >
        <div className={`calc ${hoveredId && hoveredId !== id ? 'blur' : ''}`}></div>
        <MdPersonPin/>
        <div className="info">
            <strong>{name}</strong>
            <span>{professionalFunction}</span>
            <a href={`tel:${tel.replaceAll(' ', '')}`}>{tel}</a>
        </div>
    </div>
  )
}
