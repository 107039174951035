import React, { useEffect, useRef, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Caution = ({icon: Icon, strong, text, link, url}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cautionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      {
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0.1, 
      }
    );

    if (cautionRef.current) {
      observer.observe(cautionRef.current);
    }

    return () => {
      if (cautionRef.current) {
        observer.unobserve(cautionRef.current);
      }
    };
  }, []);

  
  console.log(isVisible);
  

  return (
    <div ref={cautionRef} className={`caution ${isVisible ? 'scale' : 'descale'}`}>
        <Icon />
        <div className="text">
            <strong>{strong}</strong> 
            <p>{text}</p>
            {link !== "" &&
            <Link to={`/${url}`}>{link}</Link>
            }
        </div>
    </div>
     
  );
};

export default Caution;