import React, {useEffect, useState, useContext} from 'react'
import Linkify from './Linkify'
import { RxCross2 } from "react-icons/rx";
import { UserContext } from '../context/userContext';
import { CiEdit } from "react-icons/ci";
import AddEventForm from './AddEventForm';
import { FaFilePdf } from "react-icons/fa6";
import { FaFileLines } from "react-icons/fa6";

export default function EventModal({data, setModalOpen, position}) {

  const [timeStamp, setTimeStamp] = useState("")

  const [currentUserMail, setCurrentUserMail] = useState("")
  const [editEvent, setEditEvent] = useState(false)

  const { currentUser, userData } = useContext(UserContext)

  const replacements = {
    '/': ' / ',
    '2024': '2024  |',
  };

  console.log(data);
  

  console.log(currentUser)

  const replaceAll = (text, replacements) => {
    let result = text;

    Object.entries(replacements).forEach(([key, value]) => {
      result = result.replaceAll(key, value);
    });

    return result;
  };

  const updatedText = replaceAll(timeStamp, replacements);

  
  useEffect(() =>{
    if(!(currentUser === null || currentUser === undefined)) {
      setCurrentUserMail(currentUser.auth.currentUser.email)
    }
  }, [currentUser])


  console.log(userData.email === currentUserMail);
  
  const formatTime = async () => {

    if(data.start.toLocaleString().slice(0,10) === data.end.toLocaleString().slice(0,10)) {
      setTimeStamp(data.start.toLocaleString().replace(":", "h").slice(0, 16) + " - " + data.end.toLocaleString().replace(":", "h").slice(11, 16))
    } else {
      setTimeStamp(data.start.toLocaleString().replace(":", "h").slice(0, 16) + " - " + data.end.toLocaleString().replace(":", "h").slice(0, 16))
    }
  }
 

  useEffect(() => {
    formatTime()
  }, [])
  
  
  return (
    <div style={{ top: position + "px" }} className="eventModal">
      <div onClick={() => setModalOpen(false)} className="overlay"></div>
      <div className="modalBox">
        <div className="close" onClick={() => setModalOpen(false)}>
          <RxCross2 />
        </div>
        {data && (
          <>
            {editEvent ? (
              <div className="addEvents">
              <AddEventForm preloadedData={data} setModal={setModalOpen}/>
              </div>
            ) : (
              <>
                <div className="event">
                  <h2>{data.title}</h2>
                  <h4>{updatedText}</h4>
                  {data.descriptif !== "" &&
                  <div className="description">
                    <Linkify>{data.descriptif}</Linkify>
                  </div>
                  }
                <div className="animatorPlusPlace">

                {Object.values(data.animateurs[0]).every(value => value !== "") && 
                <div className="animators">
                <h5>Animé par :</h5>
                {data.animateurs.map(animator => (
                  <div className="animator">
                    {!animator.logo.src ? 
                    <a href={animator.url}>{animator.nom}</a>
                    : <a href={animator.url}><img src={animator.logo.src} alt=""  title={animator.nom} /></a>
                  }
                  </div>
                ))
              }
                 </div>
                }
                {Object.values(data.lieu).every(value => value !== "") &&
                 <div className="lieu">
                  <h5>Lieu :</h5>
                   {!data.lieu.logo ? 
                     <a href={data.lieu.url}>{data.lieu.nom}</a>
                     : <a href={data.lieu.url}><img src={data.lieu.logo.src} alt="" title={data.lieu.nom} /></a>
                    }
                  </div>
                 } 
                </div>
                  {data.fichiers.length > 0 &&
                  <>
                  <h5>Fichiers additionnels :</h5>
                  <div className="fichiers">

                  {data.fichiers.map(fichier => (
                    fichier.type.includes("image") ? 
                    <div className="file">
                      <img src={fichier.url} onClick={() => window.open(fichier.url, '_blank')}></img>
                      <div className="bkgd"  style={{backgroundImage: `url(${fichier.url})`}}></div>
                    </div>
                    : fichier.type.includes("pdf") ? 
                    <div className="file"><a href={fichier.url} target='_blank'><FaFilePdf /></a></div>
                    : <div className="file"><a href={fichier.url} target='_blank'><FaFileLines /></a></div>
                  ))
                 }
                  </div>
                 </>
                 }
                </div>
  
                {currentUser && userData.email === currentUserMail && (
                  <button onClick={() => setEditEvent(true)} className="editEventBtn">
                    <CiEdit /> <span>Modifier l'événement</span>
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}