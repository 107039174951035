import React, { useState, useEffect, useContext } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import DropDown from './DropDown';
import { UserContext } from '../context/userContext';
import { FaUser } from "react-icons/fa";

export default function Nav({deviceWidth, mobileNavActive, setMobileNavActive}) {

    const [dropdown, setDropdown] = useState("");
    const [currentUserMail, setCurrentUserMail] = useState("")
    const { currentUser, userData } = useContext(UserContext)
    const [nav, setNav] = useState([
      {
        id: '1',
        titre: "Actualité",
        url: "#action_Rse",
        subMenu: [{}]
      },
      {
        id: '2',
        titre: "Qu’est-ce que la RSE ?",
        url: "#qu_est_ce_que_la_rse",
        subMenu: [
          {
            titre: "Les 3 piliers de la RSE",
            url: "#les_3_piliers_de_la_RSE",
          },
          {
            titre: "Pour quelle plus-value ?",
            url: "#la_RSE_pour_quelle_plus_value",
          },
        ]
      },
     
      {
        id: '3',
        titre: "Améliorer mes pratiques",
        url: "#ameliorer_mes_pratiques_RSE",
        subMenu: [
          {
            titre: "Des actions locales",
            url: "#des_idees_pour_demarrer",
          },
          {
            titre: "Mon bilan carbone",
            url: "#mon_bilan_carbone",
          },
        ]
      },
      {
        id: '4',
        titre: "Les experts BFC",
        url: "#les_experts_regionaux_de_la_RSE",
        subMenu: [
          {
          }, 
        ]
      },
      {
        id: '5',
        titre: "Normes et Labels RSE",
        url: "#normes_et_labels",
        subMenu: [
          {
            titre: "Normes RSE",
            url: "#les_normes_RSE",
          },
          {
            titre: "Des labels",
            url: "#les_labels",
          },
        ]
      },
      {
        id: '6',
        titre: "Ateliers RSE",
        url: "#ateliers_RSE",
        subMenu: [
          {
            titre: "Les ateliers",
            url: "#les_ateliers_RSE",
          },
          {
            titre: "Archives",
            url: "archives",
          },
        ]
      },
      {
        id: '7',
        titre: "Nous contacter",
        url: "#contact",
        subMenu: [{}]
      }
    ])

    useEffect(() =>{
      if(!(currentUser === null || currentUser === undefined)) {
        setCurrentUserMail(currentUser.auth.currentUser.email)
      }
    }, [])

    useEffect(() => {
      if((currentUser && nav.some(item => item.url !== "account"))) {
        setNav(prev => [...prev, 
        {
          id: '8',
          titre: <FaUser/>,
          url: "account",
          subMenu: [{}]

        }
      ])
      }
    }, [currentUser])
    

  console.log(nav.some(item => item.url !== "account"))
    
  return (
    <div className='navigation'>
      {deviceWidth > 1200 ?
      <>
      <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
      <ul className="nav">
      {nav.map(item => {
        return (
          <li key={item.id} id={item.id} onMouseEnter={() => setDropdown(item.id)} onMouseLeave={() => setDropdown(null)}>
            <Link to={`/${item.url}`}>{item.titre}</Link>
            {item.subMenu.length > 1 &&
            <DropDown index={item.id} subMenu={item.subMenu} dropdown={dropdown} />
          }
          </li>
          )
        })}  
      </ul>
      </>

      :

      <>
       <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
       <ul onClick={() => setMobileNavActive(!mobileNavActive)} id="burgerNav" className={mobileNavActive ? "activeBurgerNav" : "inactiveBurgerNav"}>
         <li className="bar"></li>
         <li className="bar"></li>
         <li className="bar"></li>
       </ul>
      <ul id="nav" className={mobileNavActive ? "activeNav" : "inactiveNav"}>
      {nav.map(item => {
        return (
          <li key={item.id} id={item.id}>
            <Link to={`/${item.url}`}>{item.titre}</Link>
          </li>
          )
        })}  
      </ul>
      </>


      }
    </div>
  )
}
