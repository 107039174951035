import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { TiArrowBack } from "react-icons/ti";
import Modal from '../components/Modal'
import ArchivedElement from '../components/ArchivedElement';

export default function Archives() {

  const navigate = useNavigate()

  const [modal, setModal] = useState()
  const [fullImgSrc, setFullImgSrc] = useState()
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [archives, setArchives] = useState([]);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
   };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  useEffect(() => {
    window.scrollTo(0, 0)
    loadArchives()
  }, [])

  const loadArchives = async () => {
    setArchives([])
    const ref = collection(db, "articles")
    const querySnapshot = await getDocs(ref)
    querySnapshot.forEach((doc) => {
      setArchives(archive => [doc.data(), ...archive])
     
    })

  }

  const handleFullImg = async (e) => {
    setModal(true)
    setFullImgSrc(e.target.src)
  }


  

  return (
   <>
    {modal && 
        <Modal setModal={setModal} fullImgSrc={fullImgSrc} position={scrollYPosition}/>   
    }

    <div className='archives'>
      <div className="content">
      <button className="back" onClick={() => navigate('/')}><TiArrowBack />Retour</button>

        <h1>Événements passés du pôle action RSE Nord Franche-Comté</h1>
        <div className="elements">
          <h3>En 2024</h3>

          {archives && archives.filter(item => item.start.includes('2024')).sort((a,b) => a.start.localeCompare(b.date)).map(archive => (
            <ArchivedElement 
              archive={archive}
              handleFullImg={handleFullImg}
            />
          ))}
         
          <h3>En 2023</h3>
          {archives && archives.filter(item => item.start.includes('2023')).sort((a,b) => a.start.localeCompare(b.date)).map(archive => (
              <ArchivedElement 
                archive={archive}
                handleFullImg={handleFullImg}
              />
          ))}
     
        </div>
      </div>
     </div>
    </>
  )
}
