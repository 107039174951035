import { createContext, useState, useEffect } from "react";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    browserSessionPersistence,
    browserLocalPersistence,
    setPersistence
} from "firebase/auth"
import {db, auth} from "../firebase-config"
import { doc, getDoc} from "firebase/firestore"; 

export const UserContext = createContext()

export function UserContextProvider(props) {

          
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [loadingData, setLoadingData] = useState(true);

    const signUp = (email, pwd) => createUserWithEmailAndPassword(auth, email, pwd)
    const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd)
    if(stayLoggedIn === true) {
       setPersistence(auth, browserLocalPersistence)
       .then(() => {
          
        return;
      }).catch((error) => {
        
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    } else if(stayLoggedIn === false) {
        setPersistence(auth, browserSessionPersistence)
        .then(() => {
          
          return signIn();
        }).catch((error) => {
          
            const errorCode = error.code;
            const errorMessage = error.message;
          });
    }
   



    console.log(stayLoggedIn)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setCurrentUser(currentUser)
            setLoadingData(false)
        })

  
        return unsubscribe;

    

    }, []) 


   let [userData, setUserData] = useState({})

   useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
            try {
                const snapshot = await getDoc(doc(db, "users", user.uid));
                if (snapshot.exists()) {
                    setUserData(snapshot.data());
                } else {
                    console.log("Aucun document trouvé pour cet utilisateur.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données utilisateur :", error);
            }
        } else {
            console.log("Utilisateur non authentifié");
        }
    });

    return () => unsubscribe(); // Nettoyage de l'écouteur d'authentification
}, []);


  console.log(userData)

    //modal 

    const [modalState, setModalState] = useState({
        signUpModal: false,
        signInModal: false
    })

    const toggleModals = modal => {
        if(modal === "signIn") {
            setModalState({
                signUpModal: false,
                signInModal: true
            })
        }
        if(modal === "signUp") {
            setModalState({
                signUpModal: true,
                signInModal: false
            })
        }
        if(modal === "close") {
            setModalState({
                signUpModal: false,
                signInModal: false
            })
        }
    }
    return (
        <UserContext.Provider value={{modalState, toggleModals, signUp, currentUser, setStayLoggedIn, signIn, userData}}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}