import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import { CiLogout } from "react-icons/ci";
import { getAuth, onAuthStateChanged, signOut, deleteUser, updatePassword  } from "firebase/auth"
import { FaRegCalendarPlus } from "react-icons/fa";
import { MdArticle } from "react-icons/md";

export default function Account() {

    const { currentUser, userData } = useContext(UserContext)

    const navigate = useNavigate();
    
    useEffect(() => {
        if(!currentUser) {
          navigate('/')
        }

    }, [])


    const logOut = async () => {
      try {
        await signOut(getAuth())
        window.location.reload();
        console.log('signed Out');
      } catch {
        alert("Pour une raison inconnue nous ne pouvons pas vous déconnecter, veuillez vérifier votre connexion internet et réessayer.")
      }
    }

  return (
    <div className='account'>
      {userData &&
      <>
      <h2>Bonjour {userData.name}</h2>
      
      <div className="tasks">
        <button className="add" onClick={() => navigate('/ajouter_evenements')}>
        <FaRegCalendarPlus />
          Ajouter un événement
        </button>
       
      </div>

      <button className="logOut" onClick={logOut}>
        <CiLogout />
        Se déconnecter
      </button>
      </>
      }
    </div>
  )
}
