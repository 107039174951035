import React from 'react'

export default function ArchivedElement({archive, handleFullImg}) {
  return (
    <div className='archivedElement'>
          <div key={archive.ID} id='archives_2024' className={`archive ${archive.fichiers.length > 0 ? 'grid' : 'block'}`}>
              {archive.fichiers.length > 0 &&
              <div className="visuals">
               {archive.fichiers.map(fichier => {
                 return <img src={fichier.url} onClick={(e) => handleFullImg(e)}/>
                })}
              </div>
              }
              <div className="info">
                <h4>{archive.title}</h4>
                <strong style={{textAlign: "center"}}>Le {archive.start.slice(0, 10)}</strong>

                {archive.descriptif &&
                <div className="descriptif">
                  <p>{archive.descriptif}</p>
                </div>
                }
                {Object.values(archive.animateurs[0]).every(value => value !== "") &&
                <div className="animators">
                  <h5>Animé par :</h5>
                  {archive.animateurs && archive.animateurs.map((animator, index) =>  (
                        
                        animator.nom !== '' &&
                        <div className="animator" key={index}>
                          <a target="_blank" rel="noopener noreferrer" href={animator.url}>
                            <img src={animator.logo.src} alt="" title={animator.nom}/>
                          </a>
                        </div>
                      )
                  )}
                </div>
                }
                {Object.values(archive.lieu).every(value => value !== "") &&
                <div className="lieu">
                  <h5>Lieu : <a href={archive.lieu.url}>{archive.lieu.nom}</a></h5>
                  {archive.lieu.logo ? 
                    <div className="lieu_logo">
                      <a target="blank" href={archive.lieu.url}>
                        <img src={archive.lieu.logo.src} alt="" title={archive.lieu.nom}/>
                      </a>
                    </div> :
                    ""
                  }
                </div>
                }
             
              </div>
            </div>
    </div>
  )
}
