import React, { useContext, useRef, useState } from 'react';
import { doc, setDoc, addDoc } from "firebase/firestore";
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../firebase-config"
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { VscEyeClosed  } from 'react-icons/vsc'
import { VscEye  } from 'react-icons/vsc'
import {useForm} from 'react-hook-form';
import { TbEyeClosed } from "react-icons/tb";
import { TbEye } from "react-icons/tb";

const SignUp = (props) => {

    const {signUp, toggleModals} = useContext(UserContext);

    const { register, handleSubmit, setError, formState: {errors, isSubmitting, isSubmitted, isSubmitSuccessful }  } = useForm({
        mode: 'onTouched',
      })


    const navigate = useNavigate();

    const [validation, setValidation] = useState("");

    const user = getAuth().currentUser;

    const inputs = useRef([])
    const addInputs = el => {
        if (el && !inputs.current.includes(el)) {
            inputs.current.push(el)
        }
    }


    const signUpRef = useRef();



    const handleForm = async (data) => {

        let email = ""
        let name = ""
        let lastName = ""

        if(data.email) {
            email = data.email
          }
          if(data.name) {
            name = data.name
          }
          if(data.lastName) {
            lastName = data.lastName
          }
        


        if ((data.pwd.length || data.confirmPwd.length) < 6) {
            setValidation("Le mot de passe doit contenir 6 caractères minimum")
            return;
        }
        else if (data.pwd !== data.confirmPwd) {
            setValidation("Les mots de passe ne correspondent pas")
            return;
        }

        console.log(data);
    
        try {
            const cred = await signUp(
                data.email,
                data.pwd
            ).then(async (result) => {
                console.log(result);
                   
                    try {
                        onAuthStateChanged(getAuth(), async (user) => {
                            const signUpInfo = {
                                name: name,
                                lastName: lastName,
                                email: email,
                                id: user.uid
                        }
                        const ref = doc(db, "users", user.uid);
                    
                        let docRef = await setDoc(ref, signUpInfo)
                       
                        console.log(user.uid);
                        
                     })
         
                    } catch (e) {
                        console.error("Error adding document: ", e);
                        
                    }

                
            })
            signUpRef.current.reset();
            setValidation("")
            toggleModals("close")
            navigate('/ajouter_evenements')
         

        } catch (err) {
            if (err.code === "auth/invalid-email") {
                setValidation("Le format de' l'adresse mail est invalide")
            }
            if (err.code === "auth/email-already-in-use") {
                setValidation("L'adresse email est déjà utilisée")
                console.log(err);
            }
        }
    }

  

    console.log(props.confirmType);

 
    return (
        <>

            <form ref={signUpRef} onSubmit={handleSubmit(handleForm)} className="signUpForm" id="signUp-form">
                <p className="validationText">{validation}</p>
                <input ref={addInputs} {...register('email')} type="mail" required name="email" className='form-control' id='mail' placeholder='Adresse mail'/>
                <input ref={addInputs} {...register('name')} type="text" required name="name" className='form-control' id='name' placeholder='Prénom'/>
                <input ref={addInputs} {...register('lastName')} type="text" required name="lastName" className='form-control' id='lastName' placeholder='Nom'/>
               
                
                <div className="row"><input ref={addInputs} {...register('pwd')} type={props.type} required name="pwd" className='form-control' id='pwd' placeholder='Mot de Passe' />  <div className="showPassword" onClick={props.handleType}>{props.type === "password" ? <TbEye /> : <TbEyeClosed />}</div></div>
                <div className="row"><input ref={addInputs} {...register('confirmPwd')} type={props.confirmType} required name="confirmPwd" className='form-control' id='repeatPwd' placeholder='Confirmer le mot de passe' /> <div className="showPassword" onClick={props.handleConfirmType}>{props.confirmType === "password" ? <TbEye /> : <TbEyeClosed />}</div></div>
          
                <button className='submit'>S'inscrire</button>
            </form>

        </>

    )
}

export default SignUp