import React from 'react'

export default function MentionsLegales() {
  return (
    <div>
      <h1>Mentions légales</h1>

      
    </div>
  )
}
