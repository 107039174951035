import React, { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { collection, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import moment from 'moment';
import EventModal from './EventModal'
import 'moment/locale/fr'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { logDOM } from '@testing-library/react';

export default function RSECalendar({setSelectedEvent, selectedEvent, calendarRef}) {

    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
   
    const [scrollYPosition, setScrollYPosition] = React.useState(0);

    let today = new Date("11/20/2024")

   

    const handleScroll = () => {
      const newScrollYPosition = window.pageYOffset;
      setScrollYPosition(newScrollYPosition);
     };
  
     useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

    moment.locale('fr');
    const localizer = momentLocalizer(moment);

    const messages = {
        allDay: 'Toute la journée',
        previous: 'Précédent',
        next: 'Suivant',
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        agenda: 'Agenda',
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setModalOpen(true);
        console.log(event);
    };


    
    const archiveEvent = async () => {
    
        const archivePromises = events.map(async (event) => {
            if (new Date(event.start).setHours(0, 0, 0, 0) < new Date(today).setHours(0, 0, 0, 0)) {
              const docRef = doc(db, "articles", event.id);

              let archivedEvent = {
              ID: event.id,
              title: event.title,
              start: event.start.toLocaleString().slice(0, 16),
              end: event.end.toLocaleString().slice(0, 16),
              descriptif: event.descriptif,
              animateurs: event.animateurs,
              lieu: event.lieu,
              fichiers: event.fichiers
              }
              
              console.log(event.start.toLocaleString());
              
              try {
                const docSnapshot = await getDoc(docRef);
                
                if (docSnapshot.exists()) {
                  console.log("Document déjà existant.");
                } else {
                  await setDoc(docRef, archivedEvent);
                  console.log("Document créé avec succès.");
                }
              } catch (error) {
                console.error("Erreur lors de la vérification ou de l'enregistrement du document :", error);
              }
            }
          });

          await Promise.all(archivePromises);
    }

    console.log(modalOpen);
    console.log(selectedEvent);
    

    const loadEvents = async () => {
        const refCalendar = collection(db, 'calendrier');
        const querysnapshotCalendar = await getDocs(refCalendar);
        
        const eventsArray = [];
        querysnapshotCalendar.forEach((doc) => {
            const data = doc.data();
            const [startDatePart, startTimePart] = data.start.split(" ");
            const [endDatePart, endTimePart] = data.end.split(" ");
            
            const [startDay, startMonth, startYear] = startDatePart.split("/").map(Number);
            const [endDay, endMonth, endYear] = endDatePart.split("/").map(Number);
            const [startHours, startMinutes] = startTimePart.split(":").map(Number);
            const [endHours, endMinutes] = endTimePart.split(":").map(Number);

            const startDateObject = new Date(startYear, startMonth - 1, startDay, startHours, startMinutes);
            const endDateObject = new Date(endYear, endMonth - 1, endDay, endHours, endMinutes);

            eventsArray.push({
                id: data.ID,
                title: data.title,
                start: startDateObject,
                end: endDateObject,
                descriptif: data.descriptif,
                animateurs: data.animateurs,
                fichiers: data.fichiers,
                lieu: data.lieu
            });
        });
        setEvents(eventsArray);
    };

    useEffect(() => {
        setEvents([]); 
        loadEvents();
    }, []);

    useEffect(() => {
       archiveEvent()
    }, [events]);

    useEffect(() => {
        if(Object.keys(selectedEvent).length > 0) {
            setModalOpen(true)
        }
    }, [selectedEvent])

    console.log(selectedEvent);
    

    return (
        <>
        {modalOpen === true && 
            <EventModal setModalOpen={setModalOpen} data={selectedEvent} position={scrollYPosition}/>   
        }
        <div className='calendar' ref={calendarRef} style={{ margin: '50px' }}>
            {events.length > 0 &&
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    messages={messages}
                    onSelectEvent={handleSelectEvent}
                />
            }
        </div>
        </>
    );
}